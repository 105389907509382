/**
 *  Appointment Booking
 */

.appt-book {
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        fieldset.mobile-phone-fieldset {
          float: right;
          .appt-book-mobile-prefix {
            width: 25%;
            height: 40px;
          }
          .appt-book-mobile {
            width: 75%;
          }
        }
      }
    }
  }
}
