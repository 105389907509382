@import '../../../estee_base/scss/theme-bootstrap';

/*
  Important Note:
  Please note that there are a lot of "!important" added in this file.
  The documentation for this feature says that there is an iframe and there's a need to add "!important" to some properties.
  There is no specific class to add the styling so we added styling using the title value
*/

.lp-json-pollock.card {
  background-color: $color-white;
  border-radius: 12px !important;
  box-shadow: 0 0 8px $color-light-gray !important;
  display: flex;
  margin: 10px 0 !important;
  overflow: hidden;
  padding: 0 !important;
  width: 252px !important;
  .lp-json-pollock-layout-horizontal {
    display: flex !important;
  }
  * {
    border: none !important;
    padding: 0 !important;
    width: auto !important;
    word-break: normal !important;
  }
  .lp-json-pollock-layout {
    max-width: 100% !important;
  }
  .lp-json-pollock-element-button {
    button {
      color: $color-darker-grey !important;
      font-family: $font-akzidenzgrotesk;
      font-weight: 500;
      text-decoration: underline;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  /* full-offer-card-image, product-card-image */
  .BrnDej-1 img,
  .BrrFen-1 img {
    @include swap_direction(margin, 8px 12px 8px 16px !important);
    align-items: center;
    border-radius: 6px !important;
    display: inline-flex !important;
    height: 54px !important;
    justify-content: center;
    object-fit: cover;
    overflow: hidden;
    user-select: none;
    width: 54px !important;
  }
  /* product-card-item-color */
  .BrrFen-2 {
    @include swap_direction(margin, 0 6px 8px 0 !important);
    border-radius: 50% !important;
    display: inline-block !important;
    font-size: 0;
    height: 12px !important;
    overflow: hidden;
    user-select: none;
    width: 12px !important;
  }
  .BrrFen-3,
  .BrrFen-4,
  .BrrFen-5,
  .BrnDej-2,
  .BrrFen-3,
  .XrfY-1,
  .XrfY-2,
  .XrfY-3 {
    font-family: $font-akzidenzgrotesk !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    user-select: none;
    word-break: initial !important;
    word-wrap: initial !important;
  }
  .XrfY-1,
  .XrfY-2,
  .XrfY-3 {
    align-items: center !important;
    border-top: 1px solid $color-border-grey !important;
    display: inline-flex !important;
    height: 100%;
    justify-content: center !important;
    margin: 0 !important;
    padding: 10px 1.6px !important;
    text-transform: uppercase;
  }
  .Rc5Dej-1,
  .qRnRDej-1 {
    img {
      align-items: center !important;
      display: inline-flex !important;
      justify-content: center !important;
      margin: 0 !important;
      object-fit: cover;
      user-select: none;
      width: 252px !important;
    }
  }
  /* product-card-color-title */
  .BrrFen-3 {
    color: $color-dusty-grey !important;
    display: block !important;
    font-weight: 500 !important;
    font-size: 10px;
    line-height: 1;
    margin: 0 0 8px !important;
    max-width: 145px !important;
  }
  /* product-card-price-old */
  .BrrFen-4 {
    @include swap_direction(margin, 0 6px 15px 0 !important);
    color: $color-silver-grey !important;
    display: block !important;
    font-weight: 500 !important;
    font-size: 10px;
    line-height: 1;
    max-width: 136px !important;
    text-decoration: line-through;
  }
  /* product-card-price-new */
  .BrrFen-5 {
    color: $color-darkest-grey !important;
    display: block !important;
    font-weight: 500 !important;
    font-size: 10px;
    line-height: 1;
    max-width: 136px !important;
    margin: 0 0 15px !important;
  }
  /* title */
  .BrnDej-2 {
    @include swap_direction(margin, 0.875rem 1rem 0.5rem 0 !important);
    color: $color-darkest-grey !important;
    display: block !important;
    font-weight: 700 !important;
    font-size: 12px;
    line-height: 1;
    max-width: 154px !important;
  }
  .BrnDej-3 {
    @include swap_direction(margin, 0 12px 6px 0 !important);
    color: $color-dusty-grey !important;
    display: inline-block !important;
    font-weight: 500 !important;
    font-size: 10px;
    line-height: 1;
    max-width: 154px;
  }
  /* button-1 */
  .XrfY-1 {
    max-width: 126px !important;
    min-height: initial !important;
    width: 126px !important;
  }
  /* button-2 */
  .XrfY-2 {
    border-left: 1px solid $color-border-grey !important;
    max-width: 125px !important;
    width: 125px !important;
  }
  /* button-self */
  .XrfY-3 {
    max-width: 252px !important;
    width: 252px !important;
  }
  /* image-only-card */
  .Rc5Dej-1 {
    img {
      cursor: pointer;
      height: 104px !important;
    }
  }
  /* image-double-button-card */
  .qRnRDej-1 {
    img {
      height: 69px !important;
    }
  }
}
