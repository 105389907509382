// moving scss from directories:
// components
// layout

//
// scss/layout/_sticky_footer.scss
//

.page-sticky-footer {
  .page-sticky-footer__inner {
    z-index: 4; // was 1, clearing loyalty my feed
  }
  .page-sticky-footer__left .node-elc-nodeblock,
  .page-sticky-footer__right .menu > li {
    @media #{$medium-up} {
      font-size: 14px;
    }
  }
  .page-sticky-footer__left > .node-elc-nodeblock {
    &:first-child {
      border-left: none;
    }
  }
  .page-sticky-footer__right {
    .page-sticky-footer__right__inner {
      @media #{$medium-up} {
        /* float: right; */
      }
      .menu > li {
        @media #{$medium-up} {
          a b {
            // temp fix, remove <b> from cms
            font-weight: normal;
          }
        }
        &.first {
          border-left: none;
          padding-left: 0;
          @media #{$medium-up} {
            /* border-left: 1px solid $color-medium-gray; */
            padding-left: 14px;
          }
        }
        &.last {
          // disabling, last is not always language toggle
          //display: none; // update via cms after launch
          padding-right: 0px;
          @media #{$medium-up} {
            padding-right: 14px;
          }
        }
      }
    }
    .page-sticky-footer__right__offers {
      padding-right: 14px;
    }
  }
  .locator-link {
    text-transform: uppercase;
  }
}

// _beauty_chat
.beauty-chat-drawer .drawer-formatter__trigger {
  text-transform: uppercase;
  float: left;
}

//
// scss/components/header/_page_navigation.scss
//
.page-navigation {
  .menu-reference {
    &.menu-reference--loyalty,
    &.menu-reference--signin,
    &.menu-reference--account {
      @media #{$medium-up} {
        display: none;
      }
    }
  }
}

.menu-reference {
  .page-navigation & {
    &:last-child {
      margin-bottom: 20px; //clear sticky footer
      @media #{$medium-up} {
        margin-bottom: 0;
      }
    }
  }
}

.elc-user-state-anonymous .page-navigation .menu-reference--loyalty.js-active .level-1 {
  background-size: auto auto;
  background-position: 100% -6835px;
}

.level-2 {
  .menu-reference--loyalty & {
    padding: 15px 25px;
  }
  .elc-user-state-anonymous .menu-reference--loyalty & {
    display: none;
  }
}

// menu states
.elc-user-state-anonymous .page-navigation {
  .menu-reference--account {
    display: none;
  }
}

.elc-user-state-logged-in .page-navigation {
  .menu-reference--signin {
    display: none;
  }
}

//
// scss/components/header/_page_utilities.scss
//

//.page-utilities-elc-nodeblock,
.page-utilities-loyalty-elc-nodeblock {
  float: left;
}

.page-utilities__account-button {
  cursor: pointer;
  padding: 0 20px 0 10px;
  @media #{$small-only} {
    padding: 0;
  }
}

.page-utilities__account-text {
  .elc-user-state-logged-in & {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    @media #{$small-only} {
      letter-spacing: 0;
      font-size: 11px;
      padding-top: 2px;
      .js-is_signed_in {
        display: none;
      }
    }
  }
}

.user-login-state {
  @media #{$medium-up} {
    .page-utilities__loyalty & {
      position: static;
      min-width: 0;
    }
  }
}

// loyalty
.user-loyalty-state {
  @media #{$medium-up} {
    display: block;
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    right: 20px;
    min-width: 375px;
    @media #{$large-down} {
      @media #{$portrait} {
        top: 38px;
      }
    }
    #{$alt} & {
      color: $color-white;
    }
    .user-logged-out,
    .user-logged-in {
      display: none;
      &__link {
        margin-left: 9px;
        color: $color-navy;
        font-weight: normal;
        text-decoration: none;
        .js-loyalty-points-value {
          font-weight: bold;
        }
        #{$alt} & {
          color: $color-white;
        }
      }
    }
  }
  @media #{$large-up} {
    bottom: 0;
  }
  @media #{$medium-only} {
    right: 100px;
  }
  @media #{$large-only} {
    right: 10px;
  }
}

.page-utilities__loyalty {
  .user-greeting {
    margin-left: 9px;
    color: $color-navy;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      @media #{$medium-up} {
        color: $color-white;
      }
    }
  }
  .sign-out-link {
    margin-left: 7px;
    color: $color-darker-gray;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      color: $color-white;
    }
  }
}

// drupal_modules/esteelauder_global/_special_offers.scss
.special-offers-drawer .drawer-formatter__trigger {
  font-weight: normal;
  font-size: 14px;
}

// drupal_modules/esteelauder_common/_drawer_formatter.scss
.drawer-formatter__close {
  z-index: 3; //clear z-index for loyalty my feed
}

.account-mobile-landing-page {
  .account-landing-profile__link {
    height: 45px;
    line-height: 45px;
    width: 100%;
  }
}

.footer-legal {
  @media #{$medium-up} {
    .menu li:nth-child(3n + 3) {
      padding-right: 50px;
    }
  }
}

#colorbox.email_signup_sucess_popup {
  background: $color-navy;
  opacity: 0.9 !important;
  position: absolute !important;
  @media #{$medium-up} {
    background: none;
  }
  #cboxContent {
    background-color: transparent;
    height: 100% !important;
    overflow: visible;
  }
  #cboxWrapper {
    height: 100% !important;
  }
  #cboxLoadedContent {
    color: $color-white;
    overflow: visible !important;
    @media #{$small-only} {
      color: $color-navy;
    }
  }
  .email_signup_success_container {
    .email_signup_success_content {
      font-family: $akzidenz;
      font-size: 14px;
      text-transform: uppercase;
      @media #{$medium-up} {
        font-size: 15px;
      }
    }
    .email-signup__success-text,
    .email_signup_success_join_loyalty {
      font-size: 12px;
      display: block;
      font-weight: bold;
      color: #9eafff;
      margin: 20px 0;
      text-decoration: underline;
      text-align: left;
      text-transform: uppercase;
      cursor: pointer;
    }
    .email-signup__success_terms_condition {
      @media #{$medium-up} {
        font-size: 13px;
      }
      a {
        text-decoration: underline;
        color: $color-white;
      }
    }
    .email-signup__success-header {
      font-weight: bold;
      margin: 10px 0;
      font-size: 45px;
      letter-spacing: -0.02em;
      text-align: left;
      @media #{$medium-up} {
        font-size: 38px;
        letter-spacing: -0.04em;
        margin: 20px 0;
      }
    }
    .email_signup_success_join_loyalty_offer {
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      @media #{$medium-up} {
        font-size: 18px;
      }
      span {
        @media #{$medium-up} {
          display: block;
        }
      }
    }
  }
}

.estee_elist_loyalty_program {
  @media #{$medium-up} {
    margin: 10px 0;
  }
}

// loyalty intercept localisation
body .loyalty-offer-overlay {
  background: #040a2b;
  .loyalty_popover {
    @media #{$medium-up} {
      padding: 30px 50px;
    }
    #loyalty_popover__checkbox ~ .loyalty_popover__checkbox-label::after {
      background-image: url('/media/images/background-images/background-image12.png');
      background-repeat: no-repeat;
      background-size: 13px 13px;
    }
  }
  #cboxClose {
    // I have to use `!important`, because those before me have... :( DH
    top: 25px !important;
    right: 20px !important;
  }
}

.loyalty_popover {
  margin-top: 0;
  // Show the checkbox on the loyalty popover. DH
  #loyalty_popover__checkbox ~ .loyalty_popover__checkbox-label::before,
  #loyalty_popover__checkbox ~ .loyalty_popover__checkbox-label::after {
    background: $color-navy;
    @include swap_direction(border, 1px solid $color-medium-gray);
  }
  .loyalty_popover__checkbox-container .loyalty_popover__checkbox-label {
    padding-#{$ldirection}: 24px;
  }
  &__promo {
    margin-bottom: 0px;
  }
  &.signin-overlay-loyalty {
    .loyalty_popover__password {
      &.password-field {
        &.form-item {
          margin-bottom: 15px;
          position: relative;
        }
        @media #{$medium-up} {
          .password-field__info {
            top: auto;
            right: auto;
            bottom: -71px;
            left: 0;
            width: auto;
            margin-bottom: 32px;
            position: absolute;
            &::before {
              top: auto;
              right: auto;
              bottom: 100%;
              left: 10%;
              border-color: transparent transparent $color-white transparent;
            }
          }
          .password-field__rules {
            column-count: 2;
            font-size: 12px;
            list-style: none;
            margin-top: 5px;
            padding: 10px;
          }
        }
      }
    }
  }
}

// Hide the checkbox for not signed users.
footer.is-alternate {
  .anon .not_loyal {
    .loyalty-offer__terms input[type='checkbox'] {
      ~ label::before,
      ~ label::after {
        background-image: none;
        background-color: transparent;
        border: none;
      }
    }
    .loyalty-offer__terms label {
      padding-left: 0px;
    }
  }
  .not_loyal {
    .loyalty-offer__terms input[type='checkbox'] {
      ~ label::before,
      ~ label::after {
        background-color: transparent;
      }
    }
  }
}

// Hide Terms and conditions in the marketing page for anonymous or loyalty users

#loyalty__content {
  .is-member {
    .not_loyalty_join_now {
      display: none;
    }
  }
}

.elc-user-state-anonymous {
  .loyalty_mrkt_ldng,
  .loyalty_market__cta {
    .loyalty_join_signin {
      p.not_loyalty_join_now {
        display: none;
      }
    }
    .loyalty_market__btn__join_button {
      background-color: $color-black;
      color: $color-white;
      padding: 10px;
    }
  }
}

footer.is-alternate {
  .anon .my-feed-loyalty-container .not_loyal {
    .loyalty-offer__terms label {
      display: none;
    }
  }
}

.loyalty-offer-overlay {
  &#colorbox {
    height: 300px !important;
    .device-mobile & {
      height: 673px !important;
    }
    #cboxContent {
      height: 438px !important;
      .device-mobile & {
        height: 671px !important;
      }
    }
    #cboxLoadedContent {
      heigth: 436px !important;
      .device-mobile & {
        height: 669px !important;
      }
    }
  }
}
//Loyalty Welcome overlay changes
.device-mobile {
  #cboxOverlay {
    &.loyalty-offer-overlay {
      background: transparent;
      height: 675px;
    }
  }
  #colorbox {
    &.loyalty-offer-overlay {
      .loyalty_popover {
        padding: 20px 0 40px;
        &__content {
          padding: 15px 0;
        }
      }
    }
  }
}

.signin-overlay-wrapper {
  #cboxLoadedContent {
    .sign-in-component {
      &.sign-in-component--lyl-enrollment {
        padding: 28px 10px;
        overflow-y: hidden;
        .sign-in-component__form-content {
          margin-top: 0;
          h5 {
            margin-bottom: 0;
          }
        }
      }
      &.sign-in-component--loyalty {
        overflow-y: hidden;
        .sign-in-component__form-content {
          margin-top: 0;
          .text--form-help {
            label {
              letter-spacing: 0.05em;
            }
          }
        }
        .social-login.gnav {
          .social-login__container {
            margin: 0 30px;
            padding: 35px 0 0;
            .email-optin {
              width: 100%;
            }
          }
        }
        .signin-overlay__toggle {
          .signin-overlay__toggle-form--have-account,
          .signin-overlay__toggle-form--dont-have-account {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

// Track My Order
.elc-order-tracking__content {
  .elc-service-order-tracking {
    .elc-order-tracking-title {
      font-family: $optimalight;
      font-size: 50px;
      font-weight: 400;
      letter-spacing: -0.02em;
    }
    .elc-order-tracking-status-description {
      .elc-order-tracking-status-description-title {
        font-family: $optimalight;
        letter-spacing: -0.02em;
      }
    }
  }
}
