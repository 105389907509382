// SPP
.spp {
  .product-full__price-text {
    .product-full__price {
      font-weight: bold;
    }
  }
  .product-full__description-section {
    .product-full__price-per-unit {
      margin-top: 10px;
    }
    .product__inventory-status {
      li {
        margin-top: 11px;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}

.spp-product__mini-bag-button-container {
  .spp-product__mini-bag-price-size {
    font-weight: bold;
  }
  .product__inventory-status {
    text-align: center;
    .button {
      margin-top: 5px;
      width: 100%;
    }
  }
}

// QUICKSHOP
.quickshop {
  .quickshop-inline__price-per-unit {
    margin-top: 10px;
  }
  .product__inventory-status {
    li {
      margin-top: 11px;
      span {
        margin-right: 10px;
      }
    }
  }
}

// Klarna installments
.elc-right-column-section {
  .klarna-account {
    align-items: center;
    border: 1px solid $color-very-light-gray;
    display: flex;
    font-family: $arialosregular;
    font-size: 14px;
    gap: 0 6px;
    letter-spacing: normal;
    margin: 10px 30px;
    padding: 10px;
    @media #{$medium-up} {
      margin: 10px 0;
      max-width: 330px;
    }
    &__logo {
      content: url('/media/export/images/checkout/icon--klarna.svg');
      height: 33px;
      margin-#{$ldirection}: auto;
      width: 60px;
    }
    &__prepend,
    &__append {
      display: none;
    }
    &__learn-more {
      text-decoration: underline;
      white-space: nowrap;
    }
  }
}

// Estee Edit SPP pages and Quickshop
.brand-estee_edit {
  .product__price-per-unit {
    font-size: 10px;
  }
  .mpp__estee_edit_subnav,
  .spp__estee_edit_subnav {
    z-index: 99;
  }
}

.product-vto__youcam-module-container {
  z-index: 1004;
  @media #{$medium-up} {
    z-index: 99;
  }
}

.product-vto {
  .cta-vto {
    @media #{$medium-up} {
      z-index: 98;
    }
  }
}

@media #{$device-ipad_mini} and (orientation: portrait) {
  .product-full {
    .product-full__image-container {
      .product-full__images {
        .slick-dots {
          bottom: 0;
        }
      }
      .slick-initialized {
        .slick-slide {
          padding-bottom: 40px;
        }
      }
    }
  }
}

@media #{$device-ipad_mini} and (orientation: landscape) {
  .product-full {
    .product-full__image-container {
      .product-full__images {
        .slick-dots {
          bottom: 34px;
        }
      }
      .slick-initialized {
        .slick-slide {
          padding-bottom: 10px;
        }
      }
    }
    .product-full__description {
      @media #{$large-up} {
        @include swap_direction(padding, 31px 4% 0 0);
        margin-#{$ldirection}: 0;
      }
    }
  }
}

.product-full__image-container {
  .product-full__images {
    .slick-dots {
      bottom: 34px;
    }
  }
}

.elc-product-full .elc-right-column-section .elc-size-picker-container {
  &.single-size {
    vertical-align: baseline;
  }
}

.sd-full-skeleton .elc-skeleton-feature--sizePicker .single-size {
  vertical-align: bottom;
}
