.mpp {
  .product__inventory-status {
    .product__inv-status-item {
      span {
        display: block;
      }
      .button--dark {
        @extend .button--secondary;
        margin-top: 10px;
      }
    }
  }
  .product_brief__panel {
    .product__price-per-unit {
      font-size: 12px;
      font-family: 'AkzidenzGrotesk W1G';
      letter-spacing: 0.15em;
      text-transform: uppercase;
      font-weight: normal;
    }
    &.down_price {
      font-weight: bold;
    }
  }
}

.brand-aerin {
  .mpp {
    .product_brief__panel {
      .product__inventory-status {
        .product__inv-status-item {
          .button--dark {
            display: none;
          }
        }
      }
    }
  }
}

.basic_video_carouselOuter {
  .basic_video_carousel_header {
    font-size: 52px;
    margin-bottom: 60px;
  }
  .basic_video_carousel_slide_v1 .body_copy {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

#holiday-2018_container .mpp__header {
  font-family: 'OptimaDisplayLight';
  font-size: 40px;
  letter-spacing: -0.02em;
  line-height: normal;
  text-transform: none;
}

.sd-product-grid {
  .elc-grid-item-product {
    .elc-product-price-per-unit-row-wrapper {
      display: none;
    }
  }
}
