// VIDEOS DYNAMIC CONTENT
#videos_dynamic_content {
  margin-bottom: 30px;
  h2 {
    font-size: 40px;
    font-family: 'OptimaDisplayLight';
    letter-spacing: -0.02em;
    text-align: center;
    margin-bottom: 30px;
  }
  &__videos-section {
    text-align: center;
    margin-bottom: 30px;
    .link-video {
      display: inline-block;
      margin: 0 10px;
      max-width: 350px;
      width: 30%;
      .video_image {
        width: 100%;
      }
      .explore {
        height: 38px;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 12px;
        letter-spacing: 0.15em;
        font-weight: bold;
        padding-top: 20px;
        img {
          vertical-align: middle;
          padding-left: 10px;
        }
      }
      .hidden {
        display: none;
      }
    }
    .link-video.active {
      opacity: 1;
    }
    .link-video.inactive {
      opacity: 0.4;
    }
  }
  &__content-section {
    .video-content {
      display: none;
    }
    .video-content.show {
      display: block;
    }
    .boutique-tout {
      max-width: 975px;
      margin: 100px auto 20px auto;
      width: 100%;
      height: auto;
      .boutique-tout-image {
        max-width: 975px;
        height: auto;
        width: 100%;
      }
      .boutique-tout-copy-block-wrapper {
        bottom: 95px;
        .boutique-tout-copy-block > span {
          font-size: 15px;
        }
      }
      .boutique-tout-button-wrapper {
        bottom: 30px;
        .boutique-tout-button.dark {
          width: 170px;
          text-align: center;
        }
      }
    }
  }
}

#video_influencer_content {
  max-width: 1200px;
  overflow: hidden;
  padding: 0 10px;
  margin: 80px auto 0 auto;
  .hidden {
    display: none;
  }
  &__block_one {
    position: relative;
    width: 540px;
    height: 415px;
    float: left;
  }
  &__block_one_image {
    position: absolute;
    top: 0;
    left: 0;
  }
  &__block_one_text {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 540px;
    margin-top: 80px;
    .block_one_cta {
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
    }
    .block_one_copy {
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 0 20px 0;
    }
    .block_one_link {
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
    }
    .block_one_para {
      margin-bottom: 20px;
    }
  }
  &__block_two {
    width: 540px;
    height: 360px;
    float: right;
    margin-top: 105px;
    .block_two_eyebrow {
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }
    .influencer_block_content {
      position: relative;
    }
  }
  &__block_two_image {
    position: absolute;
    top: 0;
    left: 0;
  }
  &__block_two_text {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 534px;
    margin-top: 25px;
    .block_two_cta {
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
      padding-top: 100px;
    }
    .block_two_author {
      font-style: italic;
    }
  }
  &__block_three {
    width: 100%;
    text-align: center;
    float: left;
    position: relative;
    margin-top: 40px;
    .block_three_eyebrow {
      position: absolute;
      width: 100%;
      font-size: 60px;
    }
    .influencer_block_content {
      padding-top: 39px;
    }
    .block_three_link {
      margin-top: 45px;
    }
  }
  &__block_three_text {
    position: absolute;
    top: 115px;
    left: 0;
    width: 100%;
  }
}

#review_with_tout_item {
  overflow: visible;
  background-repeat: no-repeat;
  background-position: -80px 80px;
  margin-top: 80px;
  &__content_wrapper {
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
  }
  &__review_content {
    float: left;
    max-width: 300px;
    margin-top: 60px;
    width: 28%;
    .review_quote {
      font-family: 'OptimaDisplayLight';
      font-size: 30px;
      margin-bottom: 10px;
    }
    .review_author {
      font-style: italic;
    }
    .review_link {
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
      background: url('/media/export/cms/double_wear_video_campaign/pc/quickshop_review_star.png') no-repeat center top;
      padding-top: 20px;
      display: block;
      width: 200px;
      text-align: center;
      margin: 15px 0 20px 0;
    }
    .review_button {
      text-align: center;
      width: 200px;
    }
    .review_shop_link {
      border: 1px solid #040a2b;
    }
  }
  &__review_image {
    margin-bottom: 20px;
  }
  &__tout_content {
    float: right;
    max-width: 830px;
    position: relative;
    text-align: center;
    margin-top: 60px;
    width: 70%;
    .tout_title {
      font-family: 'OptimaDisplayLight';
      letter-spacing: -0.02em;
      font-size: 94px;
      line-height: 94px;
      color: #040a2b;
      position: absolute;
      top: -65px;
      width: 100%;
    }
  }
  &__tout_image {
    width: 100%;
  }
  &__tout_text {
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 1130px) {
  #video_influencer_content__block_one,
  #video_influencer_content__block_two,
  #video_influencer_content__block_three {
    margin: 40px auto 0 auto;
    float: none;
  }
  #video_influencer_content {
    margin: 0px auto 0 auto;
  }
}
