.spp-engraving {
  overflow: auto;
  @media #{$large-up} {
    overflow: hidden;
  }
  &__engraving--forms {
    @include swap_direction(margin, 10px 0 0 25px);
    color: #{$color-navy};
    text-align: center;
    width: 360px;
    @media #{$large-up} {
      width: 460px;
    }
    @media #{$device-ipad_mini} {
      @include swap_direction(padding, 45px 21px 25px 15px);
      width: 768px;
    }
  }
  .engraving-form-choice-46 {
    .engrave-placement {
      position: relative;
      @media #{$large-up} {
        margin-#{$rdirection}: 16px;
        position: absolute;
      }
    }
  }
  .engraving-form-choice-47 {
    .engrave-placement {
      #{$rdirection}: 62px;
      top: 179px;
      width: 130px;
      @media #{$large-up} {
        #{$rdirection}: 102px;
        top: 346px;
      }
    }
    .canvas-orig {
       &.preview-copy,
        .preview-copy-capitalise {
          color: #{$color-brown-engraved};
          -webkit-text-stroke-color: transparent;
      }
    }
  }
  .engraving-form-choice-60 {
    .engrave-placement {
      #{$rdirection}: 30px;
      top: 79px;
      transform: rotate(90deg);
      @media #{$large-up} {
        #{$rdirection}: 45px;
        top: 118px;
        width: 180px;
      }
      .canvas-orig {
        @media #{$large-up} {
          font-size: 26px;
        }
        &.white-emerald {
          color: #{$color-white};
          -webkit-text-stroke-color: transparent;
        }
      }
    }
  }
  .engraving-form-choice-63 {
    .spp-engraving {
      &__engraving--form-preview {
        .engrave-placement {
          #{$rdirection}: 39px;
          font-size: 8px;
          top: 174px;
          width: 180px;
          input.canvas-orig {
            font-size: 12px;
          }
          @media #{$large-up} {
            #{$rdirection}: 81px;
            font-size: 10px;
            top: 337px;
          }
          .canvas-orig {
            &.preview-copy,
            .preview-copy-capitalise {
              font-size: 10px;
              text-transform: none;
              @media #{$large-up} {
                font-size: 16px;
              }
            }
            &.dark-brown {
              color: #{$color-brown-engraved};
              -webkit-text-stroke-color: transparent;
            }
          }
        }
      }
    }
  }
  .engraving-form-choice-62 {
    .spp-engraving {
      &__engraving--form-preview {
        .engrave-placement {
          #{$rdirection}: 68px;
          font-size: 8px;
          top: 185px;
          input.canvas-orig {
            font-size: 12px;
          }
          @media #{$large-up} {
            #{$rdirection}: 0;
            font-size: 10px;
            top: 347px;
          }
          .canvas-orig {
            &.preview-copy,
            .preview-copy-capitalise {
              font-size: 8px;
              text-transform: none;
              @media #{$large-up} {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .engraving-form-choice-61 {
    .spp-engraving {
      &__engraving--form-preview {
        .engrave-placement {
          #{$rdirection}: 38px;
          font-size: 8px;
          top: 175px;
          width: 180px;
          @media #{$large-up} {
            #{$rdirection}: 81px;
            font-size: 10px;
            top: 350px;
          }
          input.canvas-orig {
            font-size: 12px;
          }
          .canvas-orig {
            &.preview-copy,
            .preview-copy-capitalise {
              font-size: 8px;
              text-transform: none;
              @media #{$large-up} {
                font-size: 14px;
              }
            }
            &.dark-brown {
              color: #{$color-brown-engraved};
              -webkit-text-stroke-color: transparent;
            }
          }
        }
      }
    }
  }
 .engraving-form-choice-67 {
   .spp-engraving {
     &__engraving--form-preview {
       .engrave-placement {
         #{$rdirection}: 38px;
         top: 200px;
         width: 175px;
         @media #{$large-up} {
           #{$rdirection}: 80px;
           top: 370px;
         }
       }
     }
   }
 }
 .engraving-form-choice-43 {
    .engrave-placement {
      @include swap_direction(margin, -28px -4px 0 3px);
      position: relative;
      top: 235px;
      @media #{$large-up} {
        @include swap_direction(margin, 0 6px 110px 3px);
        position: absolute;
        width: 339px;
      }
      .preview-copy {
        color: #{$color-brown-engraved};
        font-size: 12px;
        -webkit-text-stroke-color: transparent;
        @media #{$large-up} {
          font-size: 14px;
        }
      }
    }
  }
  &__engraving--edit-form {
    .engraving__title {
      font-size: 13px;
    }
  }
  &__engraving--form-line {
    float: #{$ldirection};
    width: 100%;
    @media #{$large-up} {
      width: 370px;
    }
  }
  &__element {
    float: #{$ldirection};
    margin-top: 10px;
    width: 100%;
    &__button {
      float: #{$ldirection};
      pointer-events: none;
      .engraving-font {
        background: #{$color-white};
        border: #{$color-light-gray};
        color: #{$color-navy};
        padding: 0 20px;
        width: auto;
        &.change-font {
          &.cta {
            border: 2px solid #{$color-navy};
            color: #{$color-navy};
          }
        }
      }
    }
  }
  &__engraving--subtotaling {
    max-width: 370px;
    @media #{$device-ipad_mini} {
      max-width: 700px;
    }
    .subtotal-wrap {
      @media #{$device-ipad_mini} {
        float: #{$rdirection};
      }
    }
  }
  .engraving-save {
    &.cta {
      background: #{$color-navy};
    }
  }
  .engrave-placement {
    #{$rdirection}: 60px;
    position: absolute;
    top: 180px;
    @media #{$large-up} {
      #{$rdirection}: 0;
      top: 310px;
    }
    .preview-copy {
      font-size: 10px;
      @media #{$large-up} {
        font-size: 14px;
      }
    }
  }
  .engrave-bottom-text2 {
    margin-top: 18px;
  }
  .button-right {
    margin-#{$ldirection}: 20px;
  }
  .engraving-msg {
    bottom: 5px;
    max-width: 320px;
    @media #{$large-up} {
      margin-top: 0;
      max-width: 400px;
    }
  }
}
