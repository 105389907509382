/* Account signin page */
#signin {
  .sign-in-component {
    width: 100%;
    max-width: 950px;
  }
  .sign-in-page {
    .social-login {
      position: relative;
      &__container {
        width: 100%;
        margin: 0 auto;
        padding: 35px 30px 0;
        @media #{$medium-up} {
          width: auto;
        }
      }
      &__divider {
        width: 100%;
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
      }
      &__terms,
      &__email-opt-in {
        width: 100%;
        margin: 0;
        text-align: $ldirection;
        padding: 0;
        margin-top: 10px;
        p {
          font-size: 12px;
        }
      }
      &__opt-in-label {
        text-transform: none;
      }
    }
  }
}
/* GNAV styling */
.signin-overlay-wrapper {
  #cboxLoadedContent {
    .sign-in-component {
      padding-top: 30px;
      &__form-content {
        margin-top: 30px;
      }
      .social-login {
        &.gnav {
          min-width: 312px;
          margin: 0;
          position: relative;
        }
        &__container {
          padding: 35px 30px 0;
        }
        .fb-login-button {
          text-align: center;
          width: 100%;
          @media #{$medium-up} {
            text-align: $ldirection;
          }
        }
        &__divider {
          width: 100%;
          position: absolute;
          top: 0;
          #{$ldirection}: 0;
          @media #{$medium-up} {
            width: 100%;
          }
        }
        &__terms {
          font-size: 12px;
        }
        &__email-opt-in {
          margin-top: 18px;
          label {
            text-transform: none;
          }
        }
        .tooltip {
          display: block;
        }
      }
    }
  }
}
/* Checkout Signin Styling */
.checkout__content {
  .social-login {
    &__email-opt-in {
      text-align: $ldirection;
      margin-top: 20px;
    }
    &__container {
      width: 90%;
      margin: 0 auto;
      @media #{$medium-up} {
        padding: 20px 0;
        width: auto;
      }
    }
    &__opt-in-label {
      text-transform: none;
    }
    &__terms {
      text-align: $ldirection;
      padding: 0;
      p {
        font-size: 12px;
      }
    }
    &__divider {
      margin: 30px auto 20px;
      width: 100%;
    }
  }
}
/* Order confirmation page */
.confirmation-panel,
#confirm {
  .social-login {
    padding: 0;
    &__container {
      text-align: $ldirection;
      padding-bottom: 0;
      margin-bottom: 20px;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__title {
      display: block;
      font-size: 45px;
      font-family: $optimalight;
      text-transform: capitalize;
      letter-spacing: 0;
      @media #{$medium-up} {
        text-align: center;
      }
    }
    &__email-opt-in {
      margin-#{$ldirection}: 0;
      label {
        font-size: 15px;
        letter-spacing: 0.05em;
      }
    }
    .fb-overlay-container {
      float: $ldirection;
    }
    .facebook-disclaimer-container {
      margin-top: 65px;
      .fb-disclaimer {
        font-size: 15px;
        letter-spacing: 0.05em;
      }
    }
  }
}
/* Social info section on Account preferences page */
.social-info {
  &__content a {
    text-decoration: underline;
  }
  @media #{$medium-up} {
    margin-#{$ldirection}: 25%;
  }
  @media #{$xlarge-up} {
    margin-#{$ldirection}: 20%;
  }
  .facebook-disclaimer-container {
    margin-top: 10px;
  }
  .tooltip {
    margin-top: 5px;
    text-decoration: underline;
    .tooltiptext-over {
      height: 155px;
      overflow-y: scroll;
      letter-spacing: 0;
      @media #{$medium-up} {
        height: 116px;
        bottom: 100%;
      }
    }
  }
  &__connect {
    margin-bottom: 0;
  }
}

#social-login-section.sidebar-page {
  margin-top: 0;
  padding-top: 0;
}
/* Appointment Booking Page */
.appointments-container {
  .sign-in-form-container {
    height: auto !important;
  }
  .social-login {
    &__divider {
      width: 100%;
    }
    &__email-opt-in,
    &__terms {
      text-align: $ldirection;
      margin-top: 20px;
      padding: 0;
      font-size: 12px;
      label {
        text-transform: none;
      }
    }
  }
}

.page-wrapper {
  .social-login {
    .tooltip {
      display: block;
    }
    &__container {
      .disclaimer_tooltip {
        display: none;
      }
    }
  }
}
/* Tooltip implementation */
.social-login {
  &__container {
    .tooltip {
      text-transform: none;
      letter-spacing: 0.05em;
      font-size: 15px;
      text-align: $ldirection;
      position: relative;
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      text-decoration: underline;
      .tooltiptext-over {
        visibility: hidden;
        width: 100%;
        background-color: $color-dark-gray;
        color: $color-white;
        text-align: justify;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        z-index: 99;
        left: 0%;
        opacity: 0;
        transition: opacity 0.3s;
        font-weight: normal;
        line-height: 15px;
        bottom: 100%;
        @media #{$medium-up} {
          line-height: unset;
        }
      }
      a {
        color: $color-white;
        text-decoration: underline;
      }
      .tooltiptext-over::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 10%;
        border-width: 5px;
        border-style: solid;
        border-color: $color-dark-gray transparent transparent transparent;
      }
      &:hover .tooltiptext-over {
        visibility: visible;
        opacity: 1;
      }
    }
    .social-login__terms {
      display: none;
    }
    .disclaimer_tooltip {
      display: none;
    }
  }
  label {
    text-transform: none;
    margin-top: 10px;
    text-align: $ldirection;
    letter-spacing: 0.05em;
  }
}

.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  width: 164px;
  height: 22px;
  position: relative;
  display: inline-flex;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

#cboxLoadedContent {
  .sign-in-component {
    overflow-y: scroll;
  }
}

.signin-overlay-wrapper.signin-overlay-loyalty {
  #cboxLoadedContent {
    .sign-in-component {
      &__registration-options {
        .tooltip {
          position: relative;
          text-decoration: underline;
          .tooltiptext-over {
            visibility: hidden;
            background-color: $color-dark-gray;
            color: $color-white;
            text-align: justify;
            border-radius: 6px;
            padding: 10px;
            position: absolute;
            left: 0;
            bottom: 100%;
          }
          a {
            color: $color-white;
            text-decoration: underline;
          }
          &:hover .tooltiptext-over {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .social-login {
        .disclaimer_tooltip {
          display: none;
        }
      }
    }
  }
}

.appt-book.appointments {
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-form-container {
          overflow: initial !important;
        }
      }
    }
  }
}

.social-login.gnav {
  .tooltip {
    .tooltiptext-over {
      overflow-y: scroll;
      height: 200px;
      letter-spacing: 0;
    }
  }
}

#confirmation-page {
  .confirmation-panel {
    margin: 10px 0 0;
  }
  .registration-panel {
    padding: 0;
  }
}

.elc-user-state-logged-in {
  #cboxOverlay {
    &.signin-overlay-loyalty {
      @media #{$small-only} {
        background: none !important;
      }
    }
  }
}
