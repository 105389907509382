.section-esearch {
  .search-page {
    #search-wrapper {
      #results-container {
        #col-2 {
          #product-results {
            .results {
              .search-product {
                .search-product__details {
                  .product__inventory-status {
                    li a {
                      font-size: 12px;
                      font-weight: bold;
                      letter-spacing: 0.15em;
                      text-decoration: underline;
                      text-transform: uppercase;
                      margin-left: 7px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#colorbox.colorbox__quickshop {
  #cboxWrapper {
    #cboxContent {
      #cboxLoadedContent {
        overflow: auto !important;
        text-align: left;
      }
    }
  }
  .quickshop__image-container {
    width: 420px;
  }
}

select {
  -moz-appearance: none;
}

@media only screen and (min-width: 641px) {
  .shipping-billing-page .form-item input[type='text']::-webkit-input-placeholder,
  .shipping-billing-page .form-item input[type='tel']::-webkit-input-placeholder,
  .shipping-billing-page .form-item input[type='email']::-webkit-input-placeholder,
  .shipping-billing-page .form-item textarea::-webkit-input-placeholder,
  .shipping-billing-page .form-item .form-text::-webkit-input-placeholder {
    color: #cccccc;
  }
  .shipping-billing-page .form-item input[type='text']:-moz-placeholder,
  .shipping-billing-page .form-item input[type='tel']:-moz-placeholder,
  .shipping-billing-page .form-item input[type='email']:-moz-placeholder,
  .shipping-billing-page .form-item textarea:-moz-placeholder,
  .shipping-billing-page .form-item .form-text:-moz-placeholder {
    /* Firefox 18- */
    color: #cccccc;
  }
  .shipping-billing-page .form-item input[type='text']::-moz-placeholder,
  .shipping-billing-page .form-item input[type='tel']::-moz-placeholder,
  .shipping-billing-page .form-item input[type='email']::-moz-placeholder,
  .shipping-billing-page .form-item textarea::-moz-placeholder,
  .shipping-billing-page .form-item .form-text::-moz-placeholder {
    /* Firefox 19+ */
    color: #cccccc;
  }
  .shipping-billing-page .form-item input[type='text']:-ms-input-placeholder,
  .shipping-billing-page .form-item input[type='tel']:-ms-input-placeholder,
  .shipping-billing-page .form-item input[type='email']:-ms-input-placeholder,
  .shipping-billing-page .form-item textarea:-ms-input-placeholder,
  .shipping-billing-page .form-item .form-text:-ms-input-placeholder {
    color: #cccccc;
  }
}

#address_book,
.check-balance {
  .address_form_container,
  .gift-balance {
    text-align: left;
    .form_element,
    .qas {
      input[type='text'],
      select,
      selectBox {
        width: 100%;
      }
      &.full {
        clear: left;
        padding: 0;
      }
      .form_element {
        float: none;
        width: 100%;
        padding-left: 0px;
      }
      width: 50%;
      float: left;
      padding-left: 20px;
      text-align: left;
      margin-bottom: 20px;
    }
  }
  .required-note {
    margin: 12px 0;
  }
}

.page-navigation__hotswap {
  .depth-1 {
    &:not(.menu-container--has-nav-tout) {
      padding-bottom: 20px;
    }
  }
}

.page-navigation__hotswap {
  .depth-1 {
    &.menu-container--has-nav-tout {
      #{$ie8} & {
        padding-bottom: 50px;
      }
    }
  }
}

#cboxContent #cboxClose,
#cboxContent #cboxClose:hover {
  #{$ie8} & {
    right: 43px;
  }
}

.text-form-text,
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='password'],
textarea,
.form-text,
select {
  color: $color-black;
}

.checkout {
  .sign-in-panel {
    .form-item {
      margin-bottom: 20px;
    }
  }
}

.brand-renutriv {
  .product-full__attribute h5 {
    letter-spacing: 0.15em;
  }
  .page-wrapper {
    .BVRRRootElement {
      a,
      a:visited,
      a:hover {
        color: #ffffff;
      }
    }
  }
}

.discover_more__product-sub-header {
  text-transform: none;
}

.check-balance {
  .gift-balance {
    .form_element,
    .qas {
      input[type='text'] {
        margin-top: 10px;
      }
      span.label-content {
        font-weight: bold;
      }
    }
  }
}

#giftcard_balance {
  .error li {
    padding: 10px 0 20px;
    text-align: left;
  }
}

#balance_container {
  .account-page__gc_balance {
    span {
      font-size: 13px;
      letter-spacing: 0.1em;
      font-weight: bold;
      font-family: 'AkzidenzGrotesk W1G';
      text-transform: uppercase;
    }
  }
  .account_balance {
    height: 80px;
    width: 170px;
    line-height: 70px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid #ccc;
    margin-bottom: 15px;
  }
  input.form-submit {
    padding: 0 19px;
  }
}

.giftcard-remove-link {
  font-weight: bold;
  text-decoration: underline;
  text-transform: none;
}

.gc-order-label {
  width: 80%;
  text-align: right;
  float: left;
  margin: 5px 0;
}

.gc-order-value {
  width: 20%;
  text-align: right;
  float: left;
  font-size: 12px;
  font-weight: bold;
  margin: 5px 0;
}
/* Donations */
div.column section.donations.panel {
  padding-top: 20px;
  div.bca_donation_logo {
    float: right;
  }
  div.form_element.onoffswitch {
    position: relative;
    width: 222px;
    margin-top: 20px;
    letter-spacing: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .onoffswitch-checkbox {
    display: none !important;
  }
  label.onoffswitch-label {
    background: linear-gradient(to bottom, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%);
    letter-spacing: 0px;
    text-transform: none;
    padding: 0px;
  }
  label.onoffswitch-label:after {
    display: none !important;
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
  }
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 13px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .onoffswitch-inner:after {
    content: 'Slide right to donate \00A3 1';
    padding-right: 10px;
    color: #838279;
    text-shadow: 1px 1px #ffffff;
    background: #c0c0c1; /* Old browsers */
    background: -moz-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #c0c0c1),
      color-stop(26%, #ffffff),
      color-stop(100%, #e9e9ea)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* IE10+ */
    background: linear-gradient(to bottom, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0c0c1', endColorstr='#e9e9ea',GradientType=0 ); /* IE6-9 */
    text-align: right;
  }
  .onoffswitch-inner:before {
    content: 'Thank you for donating!';
    padding-left: 5px;
    text-shadow: 1px 1px #559bb4;
    color: #ffffff;
    text-align: left;
    background: #5eaec2; /* Old browsers */
    background: -moz-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #5eaec2),
      color-stop(26%, #6fcde4),
      color-stop(100%, #6fcde4)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* IE10+ */
    background: linear-gradient(to bottom, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5eaec2', endColorstr='#6fcde4',GradientType=0 ); /* IE6-9 */
  }
  .onoffswitch-switch {
    display: block;
    width: 60px;
    margin: 0px;
    height: 26px;
    background: #5680ac; /* Old browsers */
    background: -moz-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #5680ac),
      color-stop(100%, #1e3a5c)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* IE10+ */
    background: linear-gradient(to bottom, #5680ac 0%, #1e3a5c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5680ac', endColorstr='#1e3a5c',GradientType=0 ); /* IE6-9 */
    border: 2px solid #5a7ea9;
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .onoffswitch-checkbox.checked ~ .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  input[type='checkbox'] ~ label::before {
    border: 0px;
  }
  .onoffswitch-checkbox.checked ~ .onoffswitch-label .onoffswitch-switch {
    left: 158px;
  }
  div.form_element.onoffswitch {
    position: relative;
    width: 222px;
  }
  @media (max-width: 768px) {
    div.content {
      padding: 0px 10px;
    }
  }
}

.checkout__sidebar {
  section.donations {
    padding: 0px 19px 13px 19px;
    @media (max-width: 768px) {
      padding: 0px;
    }
  }
}

.right {
  .donations {
    .content {
      .img {
        img {
          width: 100%;
        }
      }
    }
  }
}

.page-utilities__cart-count {
  padding-right: 9px;
}

.page-utilities {
  .utility-nav__cart {
    @media #{$medium-up} {
      position: static;
    }
  }
  .utility-nav__cart-button {
    @media #{$medium-up} {
      right: 15px;
      left: unset;
    }
    @media #{$xlarge-up} {
      right: 9px;
      top: 9px;
    }
  }
  .page-utilities__cart-count {
    padding-right: 6px;
    @media #{$medium-up} {
      position: relative;
      left: 50px;
      border-left: none;
      min-width: 0;
    }
    @media #{$xlarge-up} {
      left: 45px;
    }
  }
  .sd-search-gnav-input-field {
    @media #{$medium-up} {
      right: 45px;
      left: unset;
    }
    @media #{$xlarge-up} {
      right: 64px;
    }
    .elc-search-icon-wrapper {
      .elc-gnav-search-button {
        @media #{$medium-up} {
          left: 0;
          padding-left: 0;
          padding-right: 16px;
        }
        @media #{$xlarge-up} {
          padding-right: 10px;
        }
      }
    }
    .svg-search-icon {
      @media #{$medium-up} {
        right: 27px;
      }
      @media #{$xlarge-up} {
        right: 23px;
      }
    }
    .elc-search-active-screen-wrapper {
      .elc-search-gnav-box {
        .elc-search-close-icon-wrapper {
          @media #{$medium-up} {
            right: 64px;
            padding: 0 12px 0 0;
            top: 57px;
          }
          @media #{$xlarge-up} {
            right: 76px;
          }
          .elc-search-close-icon {
            mask: url('/sites/esteelauder/themes/estee_base/img/icons/src/close.svg') center center no-repeat;
            -webkit-mask: url('/sites/esteelauder/themes/estee_base/img/icons/src/close.svg') center center no-repeat;
          }
        }
      }
    }
  }
}

/* Phone Order App */
#csr_header_holder {
  top: 65px;
  #csr_header {
    a {
      display: block;
    }
    #csr_info_box {
      border-right: 1px solid $color-red;
    }
    #csr_registered_user_info,
    #cust_cart {
      border-right: 1px solid $color-red;
    }
    .csr_table {
      td {
        vertical-align: middle;
        padding: 0 20px;
      }
      #csr_dragger {
        cursor: move;
        background: $color-red;
        width: 40px;
        padding: 0;
      }
    }
  }
}

#review-panel {
  .adpl {
    .form-item {
      .security-code {
        float: right;
        margin: -12% 0 0 0;
      }
      input.cv_number_field {
        width: 63% !important;
      }
      span.label-content {
        display: none !important;
      }
    }
    .form-item.block span.label-content {
      display: none;
    }
    .form-item.checkbox {
      span.label-content {
        display: inline-block !important;
      }
    }
    .sms-promo-wrapper {
      div.form-item {
        input.sms_input {
          width: 53% !important;
        }
      }
      div.form-item-full {
        input.sms_input {
          width: 53% !important;
        }
      }
    }
  }
  #giftcard_balance {
    .checkbox {
      .label-content {
        display: block;
      }
    }
  }
  #checkout_billing_panel {
    .form-item {
      span.label-content {
        display: inline-block !important;
      }
    }
  }
  .form-container {
    width: 50%;
  }
  .select-address {
    .new-address {
      .form-item {
        a.selectBox-dropdown {
          margin-bottom: 20px;
        }
      }
    }
  }
}

#shipping-panel {
  .adpl {
    .sms-promo-wrapper {
      div.form-item-full {
        input.sms_input {
          width: 65% !important;
        }
      }
    }
  }
}

#review-panel,
#shipping-panel {
  .adpl {
    #qas {
      .form-item {
        label {
          font-size: 14.5px !important;
          margin-left: -6px;
        }
      }
    }
  }
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      div#cboxLoadedContent {
        #prechat_container {
          .prechat-form-container {
            h4 {
              margin-top: 10px;
            }
            .prechat-messaging-area {
              p {
                margin: 5px 0px 8px 0px;
              }
            }
          }
        }
      }
    }
  }
}

.pwp_october2016 {
  #cboxContent {
    padding: 0px;
  }
}
/* donations */
.donations.panel {
  margin: 20px 0px 0px 0px;
  float: left;
  width: 100%;
  h2#charity-header {
    padding: 0.3em 0.8em;
  }
  .content {
    padding: 0 !important;
    width: 100%;
    float: left;
    .donation_info_panel {
      width: 100%;
      float: left;
      padding: 5px 20px 20px 20px;
      .donation_logo {
        float: left;
        height: 100%;
        padding: 0px 20px 0px 0px;
        img {
          margin: 0;
          max-width: 100%;
          height: auto;
          vertical-align: bottom;
        }
      }
      .donation_logo_mobile {
        display: none;
        float: left;
        height: 100%;
      }
      .donation_message {
        font-size: 14px;
        line-height: 20px;
      }
      .donation_message_mobile {
        display: none;
        margin: 0% 0% 0% 25%;
        font-size: 14px;
      }
    }
    .donation_amounts_panel {
      float: left;
      width: 100%;
      h2.donation-panel__title {
        font-size: 14px;
        text-align: right;
        border-bottom: none;
        margin-bottom: 10px;
        background-color: #eff0f0 !important;
        letter-spacing: 0.2em;
        width: 100%;
        padding: 2% 1% 1% 0%;
        float: left;
        #select-amount {
          font-size: 14px;
          padding: 0px 0px 0px 20px;
          float: left;
          text-transform: uppercase;
          font-weight: bold;
        }
        #select-amount-mobile {
          display: none;
        }
        span.donation-group {
          input.field {
            height: 0.9em !important;
          }
          label {
            display: inline-block !important;
            text-transform: uppercase !important;
          }
        }
      }
    }
  }
}

#viewcart {
  #order-summary-panel.panel {
    float: left;
    width: 100%;
  }
  #shipmethod-panel {
    float: left;
    width: 100%;
  }
}

#checkout-review-panel {
  .sms-promo-wrapper {
    div.form-item {
      input.sms_input {
        width: 53% !important;
      }
    }
  }
}

.adpl input[type='email'] + label,
.adpl input[type='tel'] + label,
.adpl input[type='password'] + label,
.adpl input[type='text'] + label,
.adpl select + label {
  display: block;
  font-size: 1em;
  height: 2em;
  line-height: 1em;
  margin-top: 3em;
  margin-top: calc(-2em - 1px);
  cursor: text;
}

.adpl input[type='email'],
.adpl input[type='tel'],
.adpl input[type='password'],
.adpl input[type='text'],
.adpl select {
  margin-top: 5px;
}

.panel select.error,
.panel select.checkedEmpty,
.panel select.touched {
  border-color: red !important;
}

.checkout {
  .email-and-sms-promotions {
    .checkbox {
      label[data-required]:before {
        content: '';
      }
    }
  }
}

#confirm {
  #confirmation-panel {
    .confirmation-panel__confirm-text--donation {
      margin-bottom: 20px;
      background-color: #f0f0f0;
      padding: 3% 2% 1% 3%;
      h2.checkout__subtitle {
        font-size: 1.4em;
        font-weight: bold;
        letter-spacing: 0.03em;
        padding-top: 3px;
      }
    }
  }
}

.header-items {
  position: relative;
}

.site-header__banner {
  @media #{$medium-up} {
    display: block;
  }
}

.account-page {
  &.password-request-page {
    .password-request-page {
      &__content {
        .password-request-page {
          &__copy {
            padding-right: 4%;
          }
        }
      }
    }
  }
  &.password-sent-page {
    .password-sent-page {
      &__content {
        .password-sent-page {
          &__copy {
            padding-right: 4%;
          }
        }
      }
    }
  }
  &.password-reset-page {
    .password-reset-page {
      &__content {
        .password-reset-page {
          &__copy {
            padding-right: 4%;
          }
        }
        .form-item {
          width: 33%;
          input.field[type='password'] {
            width: 100%;
          }
        }
      }
    }
  }
}
