$color-light-orange: #fef2f2 !default;
$color-green: #07bb5b !default;
$tick: '\2713';
$cross: '\2715';
$color-gray-transparent: rgba(0, 0, 0, 0.5); //color code for gray transparent
$color-quicksilver: #9e9e9e !default;
$color-silver: #c5c5c5 !default;
$color-deepcove: #040a2b !default;
$color-whitesmoke: #f5f5f5 !default;
$color-mineshaft: #333333 !default;
$color-white-smoke: #f1f1f1 !default;
$color-dim-gray: #616161 !default;
$color-whitesmoke: #f6f6f6 !default;
$color-coconut: #fefeff !default;
$color-nero: #212121 !default;
$color-metallic: #867b7b !default;
$color-orange-engraved: #d59f78 !default;
$color-brown-engraved: #a9725c !default;
