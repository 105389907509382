.section-store-locator {
  .store-locator {
    .local-search__search-container {
      .local-search-form {
        .local-search-form__city {
          float: left;
        }
      }
    }
  }
  .selectBox-options {
    text-transform: uppercase;
  }
}

footer.page-footer {
  .footer-find-us {
    li.first {
      a.store-locator {
        margin: 22px 0;
      }
    }
  }
}

body.ie-8 {
  header.page-header {
    .header-inner {
      .page-navigation {
        width: 65% !important;
      }
    }
  }
}

div.livechat-transcripts-page {
  dl.transcripts {
    dd.transcript {
      div.transcript-lines {
        a.view-more {
          margin-top: 10px !important;
        }
      }
    }
  }
}

.customer-service {
  div.contact-us-page {
    ul.error_messages {
      li {
        min-height: 0;
      }
    }
    .address-form__fieldset {
      .button-loading {
        width: auto;
        float: right;
      }
    }
  }
  .sidebar-page__content {
    ul.cs-quick-info {
      li {
        min-height: 0;
      }
    }
  }
}
