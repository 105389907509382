.write_a_review__container {
  #pr-write {
    .p-w-r {
      .form-group[data-reactid-powerreviews$='ag139391_msq74268'],
      .form-group[data-reactid-powerreviews$='ag140770_msq75318'] {
        display: none;
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-multiselect[data-reactid-powerreviews$='whatisyourage'] {
        &::before {
          content: 'FILTER:';
          font-size: 12px;
          #{$renutriv} & {
            color: $color-white;
            font-family: $roboto-condensed;
          }
        }
      }
    }
  }
}
