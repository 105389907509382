.klarna-information {
  margin: auto;
  max-width: 660px;
  @media #{$medium-up} {
    padding: 20px;
  }
  &__header {
    background-image: url('/media/export/images/checkout/icon--klarna.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 80px;
    margin-bottom: 25px;
    text-indent: -999em;
  }
  &__list {
    counter-reset: klarna-counter;
    margin-bottom: 25px;
    margin-#{$ldirection}: 30px;
  }
  &__list-item {
    align-items: baseline;
    counter-increment: klarna-counter;
    display: flex;
    margin-bottom: 10px;
    &::before {
      content: counter(klarna-counter) '.';
      flex-basis: 30px;
      font-size: 24px;
      padding-#{$rdirection}: 10px;
    }
  }
  &__sub-text {
    color: $color-dark-gray;
    margin: 0;
    .klarna-information__link {
      border: 0;
      border-radius: 0;
      font-size: inherit;
      height: auto;
      line-height: inherit;
      padding: 0;
      text-decoration: underline;
      text-transform: none;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
