.homepage_module_wrapper {
  #extole_zone_homepage_tout,
  #extole_zone_offers_page {
    a {
      background: $color-navy;
      color: $color-white;
      display: inline-block;
      line-height: 3.5;
      padding: 0 20px;
    }
  }
}

.content-formatter__rendered-item:first-child {
  .content-block-tout {
    position: relative;
  }
  #extole_zone_homepage_tile {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

#confirmation-page {
  .refer-a-friend {
    cursor: pointer;
    &__content {
      position: relative;
      .refer-a-friend__banner {
        width: 100%;
      }
      #extole_zone_confirmation {
        height: 100%;
        position: absolute;
        width: 100%;
      }
      .mobile-hidden {
        display: none;
        @media #{$medium-up} {
          display: block;
        }
      }
      .pc-hidden {
        display: block;
        @media #{$medium-up} {
          display: none;
        }
      }
    }
  }
}

.page-navigation__menu {
  .menu-ref {
    #extole_zone_homepage_banner {
      bottom: 0;
      cursor: pointer;
      position: absolute;
      top: 35px;
      width: 21%;
    }
  }
}
