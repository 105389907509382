.checkout__panel-content {
  .kit-item {
    .cart-item {
      &__price {
        width: 15%;
      }
      &__qty {
        #{$rdirection}: 20px;
        @media #{$large-up} {
          #{$rdirection}: 0;
        }
      }
      &__total {
        &.total {
          @media #{$medium-up} {
            top: 15px;
          }
        }
      }
    }
    .collection-item {
      span {
        @media #{$medium-up} {
          display: block;
        }
      }
    }
  }
  .cart-item {
    &:last-child {
      border-bottom: none;
    }
    &__qty {
      float: #{$rdirection};
      @media #{$medium-up} {
        float: #{$ldirection};
      }
      @media #{$large-up} {
        width: 12%;
        margin-top: 12px;
      }
    }
    &__total {
      position: absolute;
      float: #{$rdirection};
      height: 20px;
      &.total {
        top: auto;
        @media #{$medium-up} {
          top: 15px;
        }
      }
    }
    .engraving__cart-control {
      #{$ldirection}: 22%;
      width: 100%;
      display: inline-block;
      margin-bottom: 10px;
      margin-top: 10px;
      @media #{$large-up} {
        width: 55%;
        margin-top: 10%;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 1.5px;
      }
      @media #{$xlarge-up} {
        width: 42%;
        margin-top: 15%;
      }
      .button {
        @include swap_direction(padding, 0 30px);
        border: 1.5px solid $color-black;
      }
      span {
        display: block;
        margin-bottom: 10px;
        .engraving-show-toggle {
          width: 100%;
          margin-#{$rdirection}: 0;
          @media #{$large-up} {
            width: 55%;
          }
          @media #{$xlarge-up} {
            width: 75%;
          }
        }
        .overlay-wrapper {
          @include swap_direction(padding, 15px 0 10px);
          @media #{$large-up} {
            @include swap_direction(padding, 15px 38px 10px 0);
          }
        }
        .overlay-link,
        .overlay-link-cms {
          text-transform: uppercase;
          display: block;
          @media #{$large-up} {
            margin-top: 2%;
          }
        }
      }
    }
    .cart-item-engraving {
      .cart-item-inner {
        .collection-item {
          a,
          span.engraved-overlay-container {
            display: none;
            @media #{$large-up} {
              display: block;
            }
          }
        }
      }
      .cart-item {
        &__thumb {
          padding-bottom: 20px;
        }
      }
    }
  }
}

.viewcart-panel {
  .collection-item {
    .overlay-wrapper {
      @include swap_direction(margin, 40% 0 15%);
    }
    .cart-item {
      &__thumb-image {
        width: 25%;
      }
      &__qty-label {
        float: #{$rdirection};
      }
    }
    &-name {
      width: 70%;
      float: #{$rdirection};
      line-height: 1.5;
      .product-name {
        width: 65%;
      }
    }
  }
  .engraving-product-message {
    width: 70%;
    float: #{$rdirection};
    .engraving-price {
      float: #{$rdirection};
    }
    .engraving-message {
      .engraved {
        &__text {
          color: $color-black;
        }
      }
    }
  }
}

.engraving-create {
  display: none;
  h3 {
    font-size: 25px;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0.3px;
  }
}

.engraving-wrapper {
  position: relative;
  @media #{$small-only} {
    margin-top: 60px;
  }
  .engraving {
    padding: 1em;
    background-color: $color-gray-background;
    float: #{$ldirection};
    width: 100%;
    .icon {
      display: inline-block;
      width: 35px;
      height: 35px;
      float: #{$rdirection};
      background: url(/media/images/close.png) no-repeat center;
      background-size: 25px;
      &--caret--up {
        display: none;
        background: url(/media/images/arrow_up.png) no-repeat center;
        background-size: 25px;
      }
      &--caret--down {
        background: url(/media/images/arrow_down.png) no-repeat center;
        background-size: 25px;
      }
    }
    .engraved-term-conditions {
      bottom: 10px;
      #{$ldirection}: 0;
      position: absolute;
      font-size: 12px;
      font-weight: bold;
    }
    .engraving__title {
      @include swap_direction(margin, 15px 0);
    }
    &__form {
      float: #{$ldirection};
      width: 100%;
      @media #{$large-up} {
        width: 60%;
      }
      &-element {
        @include swap_direction(margin, 0 0 1em);
        display: block;
        width: 100%;
        @media #{$large-up} {
          width: 60%;
        }
        .engraving__label-intro {
          float: none;
        }
        .engraving__message-count-container {
          @include swap_direction(margin, 10px 0);
          font-size: 13px;
          text-transform: none;
        }
        &-footer {
          @include swap_direction(padding, 3em 1em 0 0);
          .engraving-save {
            display: none;
            @media #{$large-up} {
              display: inline-block;
            }
          }
          input {
            margin-#{$rdirection}: 50px;
            width: 30%;
            background: $color-navy;
            color: $color-white;
          }
          .engraving-cancel {
            font-weight: bold;
            text-decoration: underline;
            text-transform: uppercase;
            font-size: 13px;
          }
          .engraving-preview-enlarge {
            background: $color-navy;
            border: $color-light-gray;
            color: $color-white;
            margin-#{$rdirection}: 10px;
          }
          .button {
            @media #{$large-up} {
              display: none;
            }
            &--disabled {
              background: $color-light-gray;
              border: $color-light-gray;
              & + .engraving-save {
                background: $color-light-gray;
                border: $color-light-gray;
              }
            }
          }
        }
      }
      &-font {
        width: auto;
      }
      input[type='radio'] {
        display: none;
        & ~ label,
        & ~ .engraving-label-font,
        & ~ .engraving-label-location {
          @include swap_direction(padding, 3px 24px);
          display: inline-block;
          position: relative;
          &:before {
            @include swap_direction(border, 1px solid $color-light-gray);
          }
          &:after {
            color: $color-black;
          }
          &:before,
          &:after {
            @include swap_direction(border-radius, 8px);
            @include swap_direction(border, 2px solid $color-white);
            box-shadow: 0 0 0 1px $color-light-gray;
            content: '';
            position: absolute;
            top: 3px;
            #{$ldirection}: 0;
            width: $checkbox-width;
            height: $checkbox-width;
          }
        }
        &:checked {
          & ~ .engraving-label-font,
          & ~ .engraving-label-location {
            &:after {
              background: $color-black;
            }
          }
        }
      }
    }
    &__image {
      float: #{$rdirection};
      display: none;
      width: 100%;
      margin-bottom: 15px;
      @media #{$large-up} {
        width: 35%;
        display: block;
      }
      &-with-sku {
        width: 100%;
      }
      &-title {
        font: 14px $akzidenz;
      }
      &-notification {
        font-size: 12px;
        line-height: 1.5;
      }
    }
    &__preview {
      &_side,
      &_front {
        position: absolute;
        top: 100px;
        text-align: center;
        width: 100%;
      }
      &_side {
        color: $color-orange-engraved;
        @media #{$large-up} {
          top: 140px;
          font-size: 9px;
        }
      }
      &_front {
        margin-#{$ldirection}: 0;
        color: $color-brown-engraved;
        @media #{$large-up} {
          top: 165px;
          font-size: 10px;
        }
      }
    }
    &__live-preview {
      width: 220px;
      height: 220px;
      position: relative;
      background: $color-light-gray;
      margin-top: 5%;
      &-text {
        &.engraving {
          &__view-multiline {
            top: 90px;
          }
        }
        &.preview_label {
          font-size: 9px;
          top: 160px;
          #{$ldirection}: 2px;
        }
        &.preview_down {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
          font-size: 14px;
          top: 46px;
          #{$ldirection}: 5px;
        }
        &-line {
          margin-top: 0;
        }
        &.preview_top {
          top: 100px;
        }
        &.preview_bottom {
          top: 202px;
        }
      }
      &-enlarge {
        position: absolute;
        top: 0;
        #{$rdirection}: 0;
        cursor: pointer;
        &.button--disabled {
          background: none;
        }
        img {
          width: 25px;
        }
      }
    }
    &__label {
      border-radius: 4px;
      display: inline-block;
      cursor: pointer;
      text-transform: capitalize;
      &-wrapper {
        @include swap_direction(margin, 1em 1em 0 0);
      }
      &-intro {
        float: #{$ldirection};
        margin-#{$rdirection}: 10px;
      }
    }
    &__type {
      text-transform: capitalize;
      font-size: 15px;
    }
    &__selected {
      border-color: $color-black;
      color: $color-black;
    }
    &__cart-error-message {
      color: $color-red;
      display: none;
    }
    &__edit {
      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
    .engraving-toggle-edit {
      margin-#{$rdirection}: 10px;
      color: $color-black;
      border: 1px solid $color-black;
      letter-spacing: 2px;
      font-size: 11px;
      width: 180px;
    }
    &__view-title {
      font-weight: bold;
      font-size: 25px;
      margin-bottom: 10px;
      letter-spacing: 1px;
    }
    &__view-message {
      float: #{$ldirection};
      width: 50%;
      margin-top: 20px;
      &-title {
        @include swap_direction(margin, 0 10px 10px 0);
        float: #{$ldirection};
      }
      &-preview {
        font-size: 20px;
        margin-bottom: 5px;
        &.engraving__choice-optima {
          top: -6px;
          position: relative;
        }
      }
      &-bottom {
        margin-top: 15px;
        float: #{$ldirection};
        width: 100%;
        font-weight: bold;
        margin-bottom: 25px;
        .service-sku-price {
          float: #{$rdirection};
        }
        .type {
          font-size: 20px;
          margin-bottom: 18px;
          text-transform: capitalize;
        }
        p {
          @include swap_direction(margin, 20px 0 40px);
        }
        & ~ p {
          @include swap_direction(margin, 15px 0);
          float: #{$ldirection};
          line-height: 1;
        }
      }
    }
    &__message-text {
      margin-top: 15px;
      display: inline-block;
      span {
        font-weight: bold;
      }
    }
    &-copy {
      margin-bottom: 7px;
      display: block;
    }
    &__placement {
      margin-bottom: 7px;
    }
    &__overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      #{$rdirection}: 0;
      bottom: 0;
      #{$ldirection}: 0;
      background: $color-gray-transparent;
      display: none;
      z-index: 999;
      .popup {
        @include swap_direction(margin, 100px 10px);
        background: $color-white;
        width: 350px;
        @media #{$large-up} {
          @include swap_direction(margin, 100px auto);
          width: 440px;
        }
        &__zoom-container {
          padding: 20px;
          span {
            float: #{$rdirection};
            cursor: pointer;
            line-height: 1;
            font-size: 40px;
            position: relative;
          }
          &:after {
            content: '';
            display: table;
            clear: both;
          }
          p.engraving__image-notification {
            display: inline-block;
            font-size: 17px;
            padding-top: 10px;
          }
          .engraving-zoom-save {
            display: inline-block;
            margin-top: 10px;
            background: $color-navy;
            color: $color-white;
            width: 100%;
            @media #{$large-up} {
              display: none;
            }
          }
          .popup_edit {
            width: 100%;
            display: inline-block;
            border: 1px solid $color-navy;
            margin-top: 10px;
            @media #{$large-up} {
              display: none;
            }
          }
        }
        &__zoom-content {
          width: 312px;
          height: 385px;
          position: relative;
          background: $color-light-gray;
          @media #{$large-up} {
            width: 400px;
            height: 410px;
          }
          .engraving {
            &__preview {
              &_side {
                font-size: 15px;
                line-height: 2;
                top: 195px;
                color: $color-orange-engraved;
                margin-#{$ldirection}: 7px;
                @media #{$large-up} {
                  top: 255px;
                  font-size: 15px;
                }
                &.preview_down {
                  font-size: 25px;
                  top: 63px;
                  @media #{$large-up} {
                    top: 83px;
                  }
                }
              }
              &_front {
                font-size: 16px;
                line-height: 2;
                top: 232px;
                color: $color-brown-engraved;
                margin-#{$ldirection}: 0;
                @media #{$large-up} {
                  top: 300px;
                  font-size: 19px;
                }
                &.preview_label {
                  font-size: 10px;
                  top: 235px;
                  @media #{$large-up} {
                    font-size: 12px;
                    top: 290px;
                    #{$ldirection}: 3px;
                  }
                }
                &.preview_top {
                  top: 145px;
                  @media #{$large-up} {
                    top: 175px;
                  }
                }
                &.preview_bottom {
                  top: 285px;
                  @media #{$large-up} {
                    top: 365px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.engraved {
  &__image {
    position: relative;
    height: 270px;
    .engrave__placement {
      &.lid {
        position: absolute;
        top: 125px;
        width: 100%;
        text-align: center;
      }
    }
  }
  &__text {
    line-height: 1.5;
    color: $color-orange-engraved;
    top: 140px;
    width: 100%;
    font-size: 26px;
    margin-#{$ldirection}: 4px;
  }
}

#cboxLoadedContent {
  .engraving {
    background-color: $color-white;
    text-align: center;
  }
}

.order-history-page {
  .sku-brief-editorial {
    .sku-brief-engraving__text {
      @include swap_direction(margin, 5px 0 15px);
      font-size: 20px;
    }
  }
}

.engraving {
  &__choice-optima {
    font-family: $optimalight;
  }
}
