@if $loyalty-redesign {
  body {
    .loyalty-offer-overlay {
      @media #{$medium-up} {
        background: $color-white;
      }
      #cboxClose {
        top: 0 !important;
        #{$rdirection}: 0 !important;
      }
      #cboxContent {
        .loyalty_popover {
          &__signup-label {
            background-color: $color-black;
            margin-top: 10%;
            overflow: hidden;
            padding: 15px;
            text-align: center;
            width: 100%;
            @media #{$medium-up} {
              margin-#{$ldirection}: 35%;
              width: 25%;
            }
          }
          &__signup-link {
            color: $color-white;
            text-decoration: none;
          }
          .loyalty_popover__checkbox-container {
            .loyalty_popover__checkbox {
              &-label {
                &:before,
                &:after {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    #loyalty__page__tout {
      #loyalty__panel__points {
        .footnote__terms {
          margin: 0;
          .text--checkbox-label {
            font-size: 11px;
            line-height: 1.4;
            margin: 10px 15px 0;
            text-align: #{$ldirection};
            @media #{$medium-up} {
              width: 47%;
              padding: 0;
              margin-top: 0;
            }
            @media #{$xlarge-up} {
              width: 27%;
              margin: 0 15px;
            }
            @media #{$xxlarge-up} {
              margin: 10px 50px 0;
              width: 35%;
            }
            &:before,
            &:after {
              display: none;
            }
          }
        }
        .e-list_not_signed_wrapper {
          @media #{$medium-up} {
            display: inline-block;
            width: 100%;
            background-size: cover;
            height: 32vw;
          }
          @media #{$device-ipad_mini} {
            background: url('/media/loyalty/account-page-non-pc-banner-tablet.jpg') no-repeat;
            background-size: cover;
          }
          .title_main {
            @media #{$medium-up} {
              margin-#{$ldirection}: 0;
              padding-top: 2vw;
            }
            @media #{$xxlarge-up} {
              padding-top: 2.5vw;
            }
          }
          .sub_title {
            @media #{$xlarge-up} {
              padding-top: 5px;
            }
            @media #{$xxlarge-up} {
              padding-top: 15px;
            }
          }
          .btn_join {
            @media #{$xxlarge-up} {
              margin-bottom: 10px;
            }
          }
          .e-list_not_signed {
            &__text {
              @media #{$medium-up} {
                width: 100%;
                font-size: 14px;
              }
            }
            &-right__text {
              @media #{$medium-up} {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .signin-overlay-loyalty {
    #cboxContent {
      background: $color-white;
    }
    .sign-in-component {
      &--loyalty {
        background: none;
        .sign-in-component__form--sign-in {
          .sign-in-component__header {
            text-transform: uppercase;
          }
        }
      }
      &__form-loyalty {
        display: none;
      }
    }
    #cboxLoadedContent {
      .sign-in-component.registration {
        @media #{$medium-up} {
          width: 700px;
          padding: 10px;
          overflow-y: auto;
          height: 500px;
          .sign-in-component__registration-options {
            margin-bottom: 0;
          }
          .sign-in-component__header {
            margin: 0;
          }
          .form-submit {
            margin-bottom: 10px;
          }
          .sign-in-component__form-content {
            h6 {
              margin: 0;
            }
          }
          .social-login.gnav {
            .social-login__container {
              padding: 25px 0 0;
            }
          }
          .signin-overlay__toggle {
            .signin-overlay__toggle-form--dont-have-account,
            .signin-overlay__toggle-form--have-account {
              margin: 10px 0;
            }
          }
          .social-login__email-opt-in {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .device-mobile {
    #colorbox {
      &.loyalty-offer-overlay {
        #cboxClose {
          top: 0 !important;
          #{$rdirection}: 0 !important;
        }
      }
    }
  }
  .loyalty_mrkt_ldng {
    .loyalty_tiers {
      .tier {
        b {
          padding-#{$rdirection}: 0;
        }
      }
    }
  }
  .loyalty__panel__notification__welcome {
    display: none;
  }
  #loyalty__content {
    #loyalty__panel__benefits {
      border-bottom: 1px solid $color-light-gray;
      @media #{$medium-up} {
        border-bottom: none;
      }
    }
  }
  #signin {
    .sign-in-page {
      .sign-in-component {
        @media #{$medium-up} {
          width: 46%;
          max-width: 574px;
        }
      }
    }
  }
}
