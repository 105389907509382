#clock {
  background: #000000;
  color: #ffffff;
  font-weight: 100;
  text-align: center;
  font-size: 25px;
  padding-top: 55px;
  padding-bottom: 30px;
}

#clock h1 {
  font-size: 24px;
  letter-spacing: 0.15em;
}

#clock > div {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
}

#clock div > span {
  display: inline-block;
}

.timetext {
  padding-top: 5px;
  font-size: 14px;
}
